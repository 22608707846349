import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When you create a Dask client, you can head over to an url to see a dashboard of what is happening. Since Dask allows us to visualize what is happening under the hood, when using a notebook:
To do that, we need two things:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jupyterlab.readthedocs.io/en/stable/getting_started/installation.html"
        }}>{`JupyterLab`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/dask/dask-labextension"
        }}>{`Dask lab extension`}</a></li>
    </ul>
    <p>{`By having the Dask Dashboard inside your notebook, you can use that to visualize any work that is being done. It can also help you diagnose performance issues that you might have with your code.`}</p>
    <h2 {...{
      "id": "install",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#install",
        "aria-label": "install permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Install`}</h2>
    <p>{`Throughout this article, I am going to be using conda to install dependencies and manage my environments. You can use pip as well if you prefer.`}</p>
    <p>{`First, let's install JupyterLab if you haven't installed already.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`conda install -c conda-forge jupyterlab
`}</code></pre>
    <p>{`The Dask extension will need you to install nodejs. Install nodeJs from conda-forge because this will install the latest node version - the one from conda is a few versions behind.`}</p>
    <pre><code parentName="pre" {...{}}>{`conda install -c conda-forge nodejs
`}</code></pre>
    <p>{`Then we need to install the Dask Extension `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`conda install -c conda-forge dask-labextension
`}</code></pre>
    <p>{`Once that is done, we have to build the extension with two commands`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`jupyter labextension install dask-labextension
jupyter serverextension enable dask\\_labextension
`}</code></pre>
    <h2 {...{
      "id": "configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuration",
        "aria-label": "configuration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuration`}</h2>
    <p>{`By default, the extension will launch a local cluster, but it gives you the ability to both launch and manage other kinds of Dask clusters. If you are using a local cluster, you don't need to do anything else.`}</p>
    <p>{`If you need to manage other kinds of clusters, then you will have to configure them accordingly from a yaml file. Please read the `}<a parentName="p" {...{
        "href": "https://github.com/dask/dask-labextension"
      }}>{`README of the Extension`}</a>{` for more information on this matter.`}</p>
    <h3 {...{
      "id": "start-your-jupyterlab-notebook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#start-your-jupyterlab-notebook",
        "aria-label": "start your jupyterlab notebook permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Start your JupyterLab notebook`}</h3>
    <p>{`The Dask extension is now installed, we will need a notebook to try out the extension. If you don't have one, a great place to start is by using the `}<a parentName="p" {...{
        "href": "http://github.com/dask/dask-tutorial"
      }}>{`Dask Tutorial`}</a>{` that you can clone from GitHub.`}</p>
    <p>{`When you have a dask notebook, we can go ahead and start the JupyterLab notebook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`jupyter lab
`}</code></pre>
    <p>{`You will get an URL that will look like `}<inlineCode parentName="p">{`http://localhost:8888/?token=<token>`}</inlineCode>{` if you go to that URL, you will see the Dask extension on the left. `}</p>
    <p>{`To be able to use the extension, you first need to create a client with Dask which will give you a Dask dashboard URL.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from dask.distributed import Client

client = Client(n_workers=4)
client
`}</code></pre>
    <p>{`Most notebooks from the tutorial will contain this step, but you might need to add the `}<inlineCode parentName="p">{`client`}</inlineCode>{` part, to get your dashboard URL. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/22d084ac5d6cc7aa493b9b1d2b8c12cd/36069/dask_client.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.6%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABSUlEQVQoz43SX2/bIBSHYX//j5arudGqSEvsTXaAAwbM4a+xwW2irkpWVdpz+dN5L5BoSqlbqS5YjRIRrbXWBWsDWo82oDPScOfRWWetTSktD5qYNhe2ScPAO0KuhBDGDYOZME0ZEjH8uh65YuMwjuOIiO5Bs9957+WkcLZ2dpyDlJPWSikpJzlLnI1JKcUY/bOPWAjx+vpz+DOidgwYAAghAIAyCkTMs/be3x5092+cc04preua17w9W7f7mm97uct/NTn67DGEIDg3xuzfqLUiohBCa/05NrWWfd+NMX3fc84/j7/GANB1HaX0Mb7dKaVOp9P5fGaMSaWV0lIqrc31SmJcSt1LqcMwXC4Xznkp5SmmlB4Oh7b9cel+cxnlnIRKak5cBoWLUNn59Xh8adsWAJZliTFu29bs/40Q0vc9ABhjpml6/yRvIPo6+hFZLhsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "dask client",
            "title": "dask client",
            "src": "/static/22d084ac5d6cc7aa493b9b1d2b8c12cd/0eb09/dask_client.png",
            "srcSet": ["/static/22d084ac5d6cc7aa493b9b1d2b8c12cd/c59a0/dask_client.png 125w", "/static/22d084ac5d6cc7aa493b9b1d2b8c12cd/86700/dask_client.png 250w", "/static/22d084ac5d6cc7aa493b9b1d2b8c12cd/0eb09/dask_client.png 500w", "/static/22d084ac5d6cc7aa493b9b1d2b8c12cd/36069/dask_client.png 503w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now use that URL `}<inlineCode parentName="p">{`http://127.0.0.1:40171/status`}</inlineCode>{` on the Dask extension tab, the buttons will turn orange to say that you can now open new windows. The great thing about this extension is that you can move windows around and have your notebook on one side and all the diagnostics windows open around your notebook.`}</p>
    <h2 {...{
      "id": "video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#video",
        "aria-label": "video permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Video`}</h2>
    <p>{`If you prefer to watch a video on how to install and use the Dask Extension, here's a great introduction from Matt.`}</p>
    <iframe className="center" width="560" height="315" src="https://www.youtube.com/embed/EX_voquHdk0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      